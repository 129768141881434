import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import Btn from "../components/button"
import HeroBg from "../components/heroBg"
import bgImage from "../images/backgrounds/hero-website-seo.png"

const NotFoundPage = ({location}) => (
  <Layout>
    <Seo 
      title="cliqit | Über cliqit"
      description="Hier erfährst du die Entstehungsgeschickte von cliqit. Wie aus Hobby eine Leidenschaft wurde. "
      lang="de"
      pathname={location.pathname}
      robots="noindex,follow"
    />
    <Section noSeparator>
      <div className="row">
        <div className="col-md-8">
          <h1 className="small">404 | Ups hier ist nichts!</h1>
          <h2 className="main-heading">Wer hat sich denn da vercliqt?</h2>
          <p className="subheading align-left">Komm gerne zurück zur Startseite und entdecke mehr von cliqit.</p>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-column flex-md-row justify-content-around justify-content-md-start">
          <Btn
            type="primary"
            text="Zur Startseite"
            href="/" 
          />
        </div>
      </div>
    </Section>
    <HeroBg 
      bgImage={bgImage}
    />

  </Layout>
)

export default NotFoundPage
